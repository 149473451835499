<template>
  <CommonsModalsModalBody
    :svg="brandComposable.isComptalib() ? 'ComptalibCreditCardSvg' : 'CreditCardSvg'"
    height="auto"
  >
    <template #title> Créer un lien de paiement par carte bancaire </template>

    <template #help>
      Créez un lien de paiement en ligne par carte bancaire en renseignant la raison sociale ou le
      nom du client ainsi que le montant TTC que vous souhaitez facturer.
    </template>

    <template #content>
      <div class="form">
        <div class="form-row">
          <div class="col-2">
            <uds-input-select-custom
              placeholder="Client"
              label="Raison sociale / nom du client"
              :value="null"
              is-required
              :error="err.idThirdParty"
            >
              <template #inputContent>
                <span v-if="currentThirdParty" class="truncate-text">{{
                  currentThirdParty.fullname || currentThirdParty.company
                }}</span>
                <span v-else class="placeholder">Choisir le client</span>
              </template>
              <template #dropdownContent="{ hide }">
                <CommonsDropdownsThirdPartyDropdown
                  :has-generics="false"
                  :has-providers="false"
                  :has-customers="true"
                  :transaction="{}"
                  :is-credit="true"
                  :ids-third-party-already-selected="{
                    idTier: null,
                    idAccount: null,
                  }"
                  @select="(form.idThirdParty = $event.idTier), hide()"
                />
              </template>
            </uds-input-select-custom>
          </div>
          <div class="col-2">
            <uds-input-amount
              label="Montant TTC"
              is-required
              :value="form.amount"
              :error="err.amount"
              @change="form.amount = $event || null"
            />
          </div>
        </div>
        <div class="form-row">
          <uds-input-checkbox
            label="Envoyer par e-mail"
            :is-checked="form.sendByEmail"
            @input="form.sendByEmail = !form.sendByEmail"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <uds-main-button size="small" :loading="isLoading" :disabled="!isValid" @click="create()"
        >Enregistrer {{ form.sendByEmail ? "et envoyer par e-mail" : "" }}</uds-main-button
      >
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import SendPaymentModal from "./SendPaymentModal.vue";

const brandComposable = useBrandsComposable();
const thirdPartyStore = useThirdPartiesStore();
const paymentStore = usePaymentStore();

const emit = defineEmits(["close", "cancel"]);

const isLoading = ref(false);
const form = ref<{ idThirdParty: null | number; amount: null | number; sendByEmail: boolean }>({
  idThirdParty: null,
  amount: null,
  sendByEmail: false,
});

const currentThirdParty = computed(() =>
  thirdPartyStore.allThirdParties.find((t) => t.id === form.value.idThirdParty),
);

const err = computed<{ [key: string]: string | null }>(() => {
  return {
    idThirdParty: isDefined(form.value.idThirdParty) ? null : "Le champ est requis.",
    amount: isDefined(form.value.amount)
      ? form.value.amount > 0.5
        ? null
        : "Le montant doit être supérieur à 0.50€."
      : "Le champ est requis.",
  };
});

const isValid = computed<boolean>(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

async function create() {
  const societyStore = useSocietyStore();

  isLoading.value = true;

  const payment = await paymentStore.create({
    idThirdParty: form.value.idThirdParty!,
    amount: form.value.amount! * 100, // Montant en cts via Stripe
    idSociety: societyStore.society!.id!,
  });

  await navigateTo(
    useBuildRouteFromQueryPropertiesComposable(
      paymentStore.queryProperties,
      // @ts-expect-error
      {},
      "/sales/payments#forceRefresh",
    ),
  );

  isLoading.value = false;
  emit("close");

  if (form.value.sendByEmail && payment) {
    const { open, close } = useModal({
      component: SendPaymentModal,
      attrs: {
        payment,
        onClose() {
          close();
        },
        onCancel() {
          close();
        },
      },
    });
    await open();
  }
}
</script>
