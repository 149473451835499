<template>
  <CommonsModalsModalBody
    :svg="brandComposable.isComptalib() ? 'ComptalibPaperPlaneSvg' : 'SendMailSvg'"
    height="auto"
  >
    <template #title>Envoyer un lien de paiement</template>

    <template #content>
      <div class="form">
        <div class="form-row">
          <div class="col-2">
            <uds-input-string
              label="Adresse e-mail"
              :value="form.to"
              is-required
              :error="err.to"
              @change="form.to = $event ?? null"
            />
          </div>
          <div class="col-2">
            <uds-input-string
              label="Cci"
              :value="form.copy"
              :error="err.copy"
              @change="form.copy = $event ?? null"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-max">
            <uds-input-string
              label="Objet"
              :value="form.object"
              is-required
              :error="err.object"
              @change="form.object = $event ?? null"
            />
          </div>
        </div>
        <div class="form-row">
          <div style="width: 100%">
            <div style="width: 100%">
              <label class="uds-input-label">Contenu <span style="color: #e02424">*</span></label>
              <CommonsInputsTextEditor v-model="form.content" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <uds-main-button size="small" :loading="isLoading" :disabled="!isValid" @click="send()"
        >Envoyer</uds-main-button
      >
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { IPaymentExpectationCustomer } from "@silexpert/core";

const brandComposable = useBrandsComposable();
const societyStore = useSocietyStore();
const paymentStore = usePaymentStore();

const hostS3 = brandComposable.getClientBrandConfig().urlS3;

const props = defineProps<{
  payment: IPaymentExpectationCustomer;
}>();

const emit = defineEmits(["close", "cancel"]);

const isLoading = ref(false);

const form = ref<{
  to: string | null;
  copy: string | null;
  object: string | null;
  content: string | null;
}>({
  to: props.payment.thirdParty?.email ?? null,
  copy: null,
  object: "Votre lien de paiement",
  content: `Bonjour,<br/>Vous trouverez ci-joint une demande de paiement d’un montant de ${
    props.payment.amount! / 100
  }€.<br/>Nous vous remercions par avance de votre règlement rapide.<br/>Merci de votre confiance !<br/>${
    societyStore.society?.commercialName ?? societyStore.society!.name
  } <a style="margin: 0; padding: 0; display: block; border: 0;font-size: 100%; font: inherit; color: white; text-decoration: none; background-color: #3c88ff; padding: 8px; border-radius: 4px;width: 90px; vertical-align: baseline;" href="https://${window.location.hostname}/payment?token=${props.payment.token}"><img src="${hostS3}config/pdt.png" style="float: left;margin-right: 8px; margin-left: 4px; height: 20px;">Payer</a>`,
});

const err = computed<{ [key: string]: string | null }>(() => {
  const { to, object, content } = form.value;
  return {
    to: isEmailString(to) ? null : "L'e-mail est invalide.",
    object: isDefined(object) ? null : "Le champ est requis.",
    content: isDefined(content) ? null : "Le champ est requis.",
  };
});

const isValid = computed<boolean>(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

async function send() {
  isLoading.value = true;

  const { to, object, content, copy } = form.value;

  await $silex().paymentExpectationCustomer.send(props.payment.id!, {
    to: to as string,
    object: object as string,
    content: content as string,
    copy: copy as string | undefined,
  });

  await navigateTo(
    useBuildRouteFromQueryPropertiesComposable(
      paymentStore.queryProperties,
      // @ts-expect-error
      {},
      "/sales/payments#forceRefresh",
    ),
  );

  isLoading.value = false;
  emit("close");
}
</script>

<style lang="scss" scoped></style>
